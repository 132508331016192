import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import HeroSlider from "../layouts/HeroSlider"
import InstagramSection from "../layouts/InstagramSection"
import InfoRow from "../layouts/InfoRow"
import CategoryProducts from "../layouts/CategoryProducts"
import OurApp from "../layouts/OurApp"
import EmbeddedForm from "../layouts/EmbeddedForm/embedded-form.component"
import VipSection from "../layouts/VipSection/vip-section.component"
import HiringSection from "../layouts/HiringSection/hiring-section.component"
import SpecialOffers from "../layouts/SpecialOffers"
import HomeSpecials from "../layouts/HomeSpecials"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "hero-img.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      profilePhoto: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      productImg: file(relativePath: { eq: "product.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      productImg2: file(relativePath: { eq: "product2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      productImg3: file(relativePath: { eq: "product3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      specialOffer: file(relativePath: { eq: "special-offers.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      specialOffer2: file(relativePath: { eq: "special-offers-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  const sliderItems = [
    {
      img: staticQuery.home,
      title: "2g and 7g  ",
      description:
        "Visit us in store and check out bundles and new strains daily!",
    },
    {
      img: staticQuery.home,
      title: "2g and 7g flower specials everyday!2 ",
      description:
        "Visit us in store and check out bundles and new strains daily!",
    },
    {
      img: staticQuery.home,
      title: "2g and 7g flower specials everyday!3",
      description:
        "Visit us in store and check out bundles and new strains daily!",
    },
    {
      img: staticQuery.home,
      title: "2g and 7g flower specials everyday!4",
      description:
        "Visit us in store and check out bundles and new strains daily!",
    },
  ]

  const products = [
    {
      category: "Edibles",
      products: [
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg,
        },
      ],
    },
    {
      category: "Sativa",
      products: [
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg2,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg2,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg2,
        },
      ],
    },
    {
      category: "Hybrid",
      products: [
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg3,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg3,
        },
        {
          title: "Churro Milk Chocolate",
          description:
            "Dusted in cinnamon sugar, this sweetly satisfying milk chocolate bar tastes like a golden, fried churro dipped in...",
          link: "/",
          img: staticQuery.productImg3,
        },
      ],
    },
  ]

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>HeroSlider</LayoutTitle>
      <HeroSlider items={sliderItems} />
      <LayoutTitle>InstagramSection</LayoutTitle>
      {/*<InstagramSection*/}
      {/*  profilePhoto={staticQuery.profilePhoto}*/}
      {/*  username="Feed Template"*/}
      {/*  qtyPost="11"*/}
      {/*  instagramPosts={[*/}
      {/*    {*/}
      {/*      postImg: staticQuery.instagramPostPhoto,*/}
      {/*      username: "escommerce",*/}
      {/*      publicationDate: "on May 17, 2022",*/}
      {/*      description:*/}
      {/*        "With a focus on empowering real estate investors, Empora is proud to\n" +*/}
      {/*        "            support the Austin Rutherford Book Tour. A digital-first title\n" +*/}
      {/*        "            company, Empora has redesigned the title and closing process to save\n" +*/}
      {/*        "            real estate investors $750 in closing costs and streamline the\n" +*/}
      {/*        "            entire process - enabling to you scale your business.",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      postImg: staticQuery.instagramPostPhoto,*/}
      {/*      username: "escommerce",*/}
      {/*      publicationDate: "on May 17, 2022",*/}
      {/*      description: "New event!",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      postImg: staticQuery.instagramPostPhoto,*/}
      {/*      username: "escommerce",*/}
      {/*      publicationDate: "on May 17, 2022",*/}
      {/*      description: "New event!",*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
      <LayoutTitle>InfoRow</LayoutTitle>
      <InfoRow
        items={[
          {
            title: "Grown locally premium grade cannabis",
            description:
              "All our products are grown and produced locally in Massachusetts. Support local businesses and check out their great work.",
            button: { title: "Learn More", url: "/" },
            image: staticQuery.home,
          },
        ]}
      />
      <LayoutTitle>CategoryProducts</LayoutTitle>
      <CategoryProducts products={products} />

      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
      <LayoutTitle>OurApp</LayoutTitle>
      <OurApp />
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
      <LayoutTitle>EmbeddedForm</LayoutTitle>
      <EmbeddedForm
        title="VIP Form"
        formUrl="https://docs.google.com/forms/d/e/1FAIpQLSfOSUX0oDayBhI2jv0lwNvwKymg1PywWyTffAfd-b2mBXoQzw/viewform?embedded=true"
      />
      <LayoutTitle>VipForm</LayoutTitle>
      <VipSection
        title="Green4All VIP List"
        description="Sign up to get entered into monthly drawings plus get exclusive releases and updates."
      />
      <LayoutTitle>HiringSection</LayoutTitle>
      <HiringSection
        title="Join The Team"
        description="Just leave us some of your information, and we'll get in touch."
      />
      <LayoutTitle>SpecialOffers</LayoutTitle>
      <SpecialOffers
        title={"Special Offers"}
        buttonLink={{ title: "Shop Products", url: "/" }}
        products={[
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer,
          },
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer2,
          },
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer,
          },
        ]}
      />
      <HomeSpecials
        title="Special Offers"
        products={[
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer,
          },
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer2,
          },
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer,
          },
          {
            name: "Butter stuff is back!",
            description:
              "28.9% Butterstuff is an indica dominant crowd favorite! Try an 1/8th(3.5g) for $30, or get AN OUNCE (28g)for only $140!",
            image: staticQuery.specialOffer,
          },
        ]}
        buttonLink={{ title: "Shop Products", url: "/" }}
      />
    </Layout>
  )
}

export default Layouts
